<template>
  <div id="app" class="layout-form bg-backdrop">
    <NavigationBar />

    <main>
      <Header />
      <FormContainer>
        <Nuxt />
      </FormContainer>
    </main>

    <Footer class="mt-auto" />
  </div>
</template>

<script>
import Header from 'chimera/all/themes/mars/components/layout/Header'
import NavigationBar from 'chimera/all/themes/mars/components/layout/NavigationBar'
import FormContainer from 'chimera/all/components/form/FormContainer'
import Footer from '~/components/layout/Footer'

export default {
  name: 'FormLayout',

  components: {
    Header,
    FormContainer,
    Footer,
    NavigationBar,
  },
}
</script>
