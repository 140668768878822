<template>
  <header
    class="header relative bg-[url('/images/hero.jpg')] sm:bg-[url('/images/hero-sm.jpg')] md:bg-[url('/images/hero-md.jpg')] lg:bg-[url('/images/hero-lg.jpg')] xl:bg-[url('/images/hero-xl.jpg')] xxl:bg-[url('/images/hero-xxl.jpg')] bg-cover bg-left md:bg-center h-[100vh]"
  >
    <div class="overlay absolute inset-0" />
    <div
      class="container absolute md:relative flex justify-center md-down:bottom-0 md-down:max-w-full p-0 sm:px-[6.25rem]"
    >
      <div class="w-full flex flex-col items-left md:items-center">
        <div class="content">
          <div
            class="md:max-w-[25rem] lg:max-w-[30rem] text-center md:text-left md:pt-[18vh] lg:pt-[13vh] xl:pt-[14vh] xxl:pt-[17vh] md:pb-0 drop-shadow-[4px_4px_8px_rgba(0,0,0,0.25)]"
          >
            <i18n
              tag="h1"
              path="title"
              class="title"
              style="text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)"
            />
          </div>
          <div
            class="header__container w-full md:flex justify-center mb-5 sm:mb-9 xl:mb-[3.5rem] md:mt-12"
          >
            <slot />
          </div>
        </div>
        <Details />
      </div>
    </div>
  </header>
</template>

<script>
import Details from '~/components/form/steps/postal/Details'

export default {
  name: 'Header',

  components: {
    Details,
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Vind dé beste rijschool bij jou in de buurt"
  }
}
</i18n>
