<template>
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_38734_9009)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 17.4998C14.6421 17.4998 18 14.142 18 9.99984C18 5.8577 14.6421 2.49984 10.5 2.49984C6.35783 2.49984 2.99996 5.8577 2.99996 9.99984C2.99996 14.142 6.35782 17.4998 10.5 17.4998ZM19.6666 9.99984C19.6666 15.0624 15.5626 19.1665 10.5 19.1665C5.43735 19.1665 1.33329 15.0624 1.33329 9.99984C1.3333 4.93723 5.43735 0.833171 10.5 0.833171C15.5626 0.833171 19.6666 4.93723 19.6666 9.99984Z"
        fill="#FF6B35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9107 13.9224C9.58527 13.597 9.58527 13.0694 9.9107 12.7439L12.6548 9.99984L9.9107 7.25576C9.58527 6.93032 9.58527 6.40268 9.9107 6.07725C10.2361 5.75181 10.7638 5.75181 11.0892 6.07725L14.4225 9.41058C14.748 9.73602 14.748 10.2637 14.4225 10.5891L11.0892 13.9224C10.7638 14.2479 10.2361 14.2479 9.9107 13.9224Z"
        fill="#FF6B35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6666 10.0002C14.6666 10.4604 14.2935 10.8335 13.8333 10.8335L7.16663 10.8335C6.70639 10.8335 6.33329 10.4604 6.33329 10.0002C6.33329 9.53992 6.70639 9.16683 7.16663 9.16683L13.8333 9.16683C14.2935 9.16683 14.6666 9.53993 14.6666 10.0002Z"
        fill="#FF6B35"
      />
    </g>
    <defs>
      <clipPath id="clip0_38734_9009">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(20.5 20) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconArrowLink',
}
</script>
