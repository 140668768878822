<template>
  <div
    class="w-full md:w-72 px-6 pt-4 pb-3 md:p-5 rounded-t-2xl md:rounded-2xl bg-lightgrey drop-shadow-[4px_4px_8px_rgba(0,0,0,0.25)]"
  >
    <i18n
      tag="p"
      path="details.title"
      class="mb-4 text-secondary leading-6 text-base font-semibold sm:text-lg"
    >
      <template #emphasis>
        <em class="text-primary not-italic">
          {{ $t('details.titleEmphasis') }}
        </em>
      </template>
    </i18n>
    <ul class="space-y-1 sm:space-y-3 pr-6">
      <li
        v-for="(point, index) in $t('details.points')"
        :key="index"
        class="flex"
      >
        <div class="w-3.5 mt-1.5 mr-2 ml-2">
          <ListIconCheck class="w-3.5" />
        </div>
        <p class="text-base font-normal text-black">
          {{ point }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import ListIconCheck from 'chimera/all/themes/mars/images/lists/ListIconCheck'

export default {
  name: 'Details',

  components: {
    ListIconCheck,
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "details": {
      "title": "Vraag {emphasis} aan van rijscholen bij jou in de buurt.",
      "titleEmphasis": "gratis offertes",
      "points": [
        "Vergelijk tot wel 6 rijscholen in jouw regio",
        "Geverifieerde partners door heel Nederland",
        "Geen verplichting"
      ]
    }
  }
}
</i18n>
