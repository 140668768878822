<template>
  <svg viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 1L4 11.01L1 8.01"
      stroke="#FF6B35"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ListIconCheck',
}
</script>
