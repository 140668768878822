<template>
  <nav class="navbar" :class="{ 'navbar--filled': filled }">
    <div class="navbar__container">
      <nuxt-link to="/" class="navbar__logo-wrapper">
        <InlineSvg class="navbar__logo h-6" :src="logo" :alt="concept" />
      </nuxt-link>

      <slot />
    </div>
  </nav>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'NavigationBar',

  components: {
    InlineSvg,
  },

  props: {
    logo: {
      type: String,
      default: 'images/logo.svg',
    },

    filled: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * @returns {{concept: string}}
   */
  data() {
    return {
      concept: process.env.concept,
    }
  },
}
</script>
